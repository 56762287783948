@font-face {
    font-family: "DIN Condensed";
    /* src: url(../../assets/styles/leaderboard.css"); */
    src: url(../fonts/DINCond-Regular.otf);
}
@font-face {
    font-family: "Conthrax";
    /* src: url(../../assets/styles/leaderboard.css"); */
    src: url(../fonts/conthrax-sb.ttf);
}

.viewTrakr-bg {
    /*background-image: url("../images/Header/viewtrakr-bg.png") !important;*/
    background-color: #000000;
    background-repeat: no-repeat;
    background-size: cover;
    /*min-height: 100vh;*/
    height: 100vh;
    width: 100%;
    overflow: hidden;
    /*display: flex;*/
    /*flex-direction: column;*/
    /*justify-content: space-between;*/
    /*overflow-y: auto;*/
}

/*buttons options start*/
.tabs {
    display: flex;
    justify-content: center;
    /*margin-top: 20px;*/
    /*width: 70%;*/
    margin-left: auto;
    margin-right: auto;
    background-color: #474747;
    border-radius: 30px;
    /*padding: 8px;*/
    position: relative;
}

.tabs button {
    flex: 1;
    padding: 6px 0;
    font-size: 16px;
    font-weight: 600;
    border: none;
    background-color: #585858;
    color: #bfbfbf;
    cursor: pointer;
    border-radius: 25px;
    transition: all 0.3s;
    position: relative;
    z-index: 1;
    width: 49%;
    margin: 0;
}

.tabs button.active.left-tab {
    background-color: #34d684;
    color: #ffffff;
    z-index: 2;
    margin-right: -7.5%;
}

.tabs button.active.right-tab {
    background-color: #7d34d7;
    color: #ffffff;
    z-index: 2;
    margin-left: -7.5%;
}

.tabs button:not(.active):hover {
    background-color: #474747;
}

.tabs button:focus {
    outline: none;
}

/*pop-out effect classes*/
.tabs button.active {
    margin-top: -3px; /* Move the active button slightly upwards */
    margin-bottom: -3px; /* Move the active button slightly downwards */
    /*padding-top: 8px;  !* Increase the top padding *!*/
    /*padding-bottom: 8px;  !* Increase the bottom padding *!*/
    z-index: 3; /* Ensures the active button is above the inactive one */
}

/*.tabs button:not(.active) {*/
/*    margin-top: 3px;  !* Adjusts the inactive button position for balance *!*/
/*    margin-bottom: 3px;*/
/*}*/

/*buttons option end */

.inner-wrapper {
    overflow-y: auto;
    /*height: inherit;*/
    /*background: #3441d6;*/
}

.add-playlist-button {
    position: absolute;
    right: 5%;
    bottom: 10%;
    z-index: 20;
}

.muted-text-bold {
    font-weight: bold;
    color: #969696;
}

.muted-text {
    color: #969696;
}

.image-wrapper {
    height: 70px;
    width: 72px;
    /*height: 100%;*/
    background: rgb(128, 128, 128);
    padding: 17px 23px;
}

.full-height-container {
    overflow-y: auto;
    overflow-x: hidden;
    height: 90%;
}

.viewTrakr-input {
    line-height: 2.2 !important;
    border: 2px solid #e9e9e9 !important;
}

.viewTrakr-input:focus {
    border-color: #1fed9f !important;
    box-shadow: 0 0 1px #1fed9f, /* Innermost shadow */ 0 0 2px #1fed9f,
        /* Middle shadow */ 0 0 3px #1fed9f,
        /* Outermost shadow */ 0 0 4px #1fed9f !important;
}

h6,
.viewTrakr-input + p {
    margin-left: 10px;
}

.success-button {
    background-color: #34d684 !important;
    color: #ffffff !important;
    border-radius: 15px !important;
}

.custom-alert {
    position: absolute;
    top: -10%; /* Initial off-screen position */
    width: 80%;
    left: 10%;
    border-radius: 50rem;
    padding: 10px 20px;
    text-align: center;
    color: #ffffff;
    font-size: 16px;
    font-weight: bold;
    transition: top 0.5s ease-in-out; /* This adds the transition effect */
    z-index: 2;
}

/* Adjust the positioning when the alert is visible */
.custom-alert-success,
.custom-alert-fail {
    top: 13% !important;
}

.custom-alert-success {
    background-color: #34d684;
}

.custom-alert-fail {
    background-color: #ee4646;
}

.playlist-card {
    height: 160px;
    width: 340px;
    margin-top: 20px;
    border-radius: 30px;
    background-size: 81% 100%;
    background-repeat: no-repeat;
}

.playlist-card-button {
    /*opacity: 80%;*/
    height: 54px !important;
    width: 65px !important;
    background: #3e4744 !important;
    border: none !important;
}

.playlist-card-button:hover {
    /*opacity: 90% !important;*/
    background: #2e3835 !important;
}

.playlist-card-button-1 {
    border-radius: 0 30px 0 0 !important;
}

.playlist-card-button-3 {
    border-radius: 0 0 30px 0 !important;
}

.link-icon-input {
    border-right: none !important;
    background: none !important;
}

.link-input {
    border-left: none !important;
    margin-right: 7%;
    line-height: 2.2 !important;
}

.link-input:focus {
    box-shadow: none !important;
    border: 1px solid #dee2e6 !important;
    border-left: none !important;
}

.go-button {
    /*margin-left: -20px;*/
    z-index: 100;
    border-radius: 50% !important;
    /*padding: 15px;*/
    height: 40px;
    width: 40px;
    background: #34d683 !important;
    border: none !important;
    position: absolute;
    /*right: -3%;*/
    left: 88%;
    top: 10%;
    font-weight: bold;
    font-size: 10px !important;
    white-space: nowrap;
}

.video-item {
    display: flex;
    align-items: center;
    /*width: 100%;*/
    /*justify-content: space-between;*/
}

.thumbnail-container {
    position: relative;
    display: inline-block;
    width: auto; /* Adjust this to match the width of the thumbnail image */
    height: auto; /* or specify a height if needed */
    border-radius: 15px;
    overflow: hidden;
}

.youtube-thumbnail-image {
    display: block;
    width: 150px; /* This will make it responsive within its container */
    height: 90px !important;
    border-radius: 10px;
}
.vimeo-thumbnail-image {
    display: block;
    width: 150px; /* This will make it responsive within its container */
    height: 90px !important;
    /*border-radius: 5px;*/
}

.thumbnail-overlay {
    /*border-radius: 5px;*/
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2em;
    height: 100%;
    width: 100%;
}

.edit-youtube-thumbnail-image {
    display: block;
    width: 220px; /* This will make it responsive within its container */
    height: 150px;
    border-radius: 30px;
}
.edit-vimeo-thumbnail-image {
    display: block;
    width: 220px; /* This will make it responsive within its container */
    height: 150px;
    border-radius: 30px;
}

.edit-thumbnail-overlay {
    border-radius: 30px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    display: flex;
    justify-content: right;
    align-items: start;
    font-size: 2em;
    padding-right: 20px;
    padding-top: 10px;
    height: 100%;
    width: 100%;
}

.viewTrakr-card {
    background-color: #f5f5f5 !important;
    border-radius: 50px !important;
}

.viewTrakr-card-footer {
    background-color: #f5f5f5 !important;
    border: none !important;
    border-radius: 0 0 50px 50px !important;
}

.video-item-details-container {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    padding-left: 10px;
    min-height: 4.3em;
    /*min-width: 55%;*/
    /*height: auto;*/
    width: 100%;
    border-radius: 0 11px 11px 0;
}
.video-item-details-container-new {
    display: flex;
    align-items: center;
    background-color: #191919;
    color: #ffffff;
    padding-left: 10px;
    min-height: 4.3em;
    /*min-width: 55%;*/
    /*height: auto;*/
    width: 100%;
    border-radius: 0 11px 11px 0;
}

.video-item-description {
    width: 100%;
    /*min-width: 50%;*/
    height: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis; /* If single line overflow occurs */
}

.go-back-button {
    background-color: #00abf5 !important;
    border-radius: 50px !important;
    width: 48%;
    color: #ffffff !important;
    padding: 4% 0 !important;
    font-size: 12px !important;
}

.save-button {
    background-color: #34d683 !important;
    border-radius: 50px !important;
    width: 48%;
    color: #ffffff !important;
    padding: 4% 0 !important;
    /*font-size: 12px !important;*/
}
.delete-playlist-button {
    background-color: #ea3824 !important;
}

.preview-parent-container {
    background-color: #e7e7e7;
    /*color: #FFFFFF;*/
}

.video-detail-card {
    background-color: #f5f5f5;
    padding: 20px 15px;
}

.video-details {
    display: flex;
    justify-content: space-between;
}

.playlist-actions {
    display: flex;
    align-items: center;
    justify-content: center;
}

.edit-playlist-button {
    background-color: #34d684 !important;
    display: flex;
    align-items: center;
    padding: 3% 0 !important;
    border: none !important;
    border-radius: 20px !important;
    z-index: 100;
    width: 70%;
}

.clone-playlist-button {
    background-color: #7d34d8 !important;
    display: flex;
    align-items: center;
    padding: 3% 0 !important;
    border: none !important;
    border-radius: 20px !important;
    z-index: 100;
    width: 70%;
}

.play-count {
    line-height: 0.8;
    padding: 2% 0;
    background-color: #e7e7e7;
    text-align: center;
    border-radius: 0 20px 20px 0;
    margin-left: -10px;
    width: 30%;
}

.profile-cover {
    /*background-color: #34d684;*/
    border-radius: 20px;
    height: 120px;
    width: 100%;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    /*background-image: url("../images/viewTrakr/blur.jpeg") !important;*/
}

.profile-tag-container {
    position: absolute;
    bottom: -15%;
    left: 5%;
    /*position: relative;*/
}

.profile-tag {
    height: 100px;
    width: 100px;
    /*background-color: #C6C6C6;*/
    position: relative;
}

.seperator {
    border-left: 2px solid;
}

.edit-profile-button {
    background-color: #313131 !important;
    /*display: flex;*/
    /*align-items: center;*/
    padding: 10px 0 !important;
    font-family: "Conthrax" !important;
    font-size: 12px !important;
    border: none !important;
    border-radius: 20px !important;
    z-index: 100;
    /*width: 70%;*/
}

.follow-button {
    background-color: #34d684 !important;
    /*display: flex;*/
    /*align-items: center;*/
    font-family: "Conthrax" !important;
    padding: 10px 0 !important;
    border: none !important;
    border-radius: 20px !important;
    font-size: 12px !important;
    z-index: 100;
    /*width: 70%;*/
}

.following-button {
    background-color: #7d34d8 !important;
    /*display: flex;*/
    /*align-items: center;*/
    font-family: "Conthrax" !important;
    padding: 10px 0 !important;
    border: none !important;
    border-radius: 20px !important;
    font-size: 12px !important;
    z-index: 100;
    /*width: 70%;*/
}

.profile-tabs {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    font-family: "DIN Condensed";
    color: #4b4b4b !important;
    /*border: none !important;*/
}

.active-profile-tab {
    border-bottom: 3px solid #a1fbf8 !important;
    color: #a1fbf8 !important;
    /*border: none !important;*/
}

.flag {
    position: absolute;
    bottom: 5%;
    right: -10%;
}

.header-dp {
    width: 50px;
    height: 50px;
    /*background-color: #C6C6C6;*/
    border-radius: 5px;
}
.tab-pane {
    height: 100%;
}
.clone-icon-div {
    background-color: #7d34d8 !important;
    padding: 10px;
    border-radius: 0 5px 0 0;
}
.clone-icon-div:hover {
    background-color: #7d34d8 !important;
}
.line-height-1 {
    line-height: 1;
}

@media only screen and (max-width: 375px) {
    .full-height-container {
        height: 85%;
    }
}
