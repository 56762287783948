@font-face {
    font-family: "Alien Encounters Solid";
    src: url("../fonts/Alien-Encounters-Bold.ttf");
}

.top-info {
    width: 86px;
    /*height: 48px;*/
    /*flex-shrink: 0;*/
    border-radius: 13px;
    background: #181818;
}
.top-points {
    color: #fff;
    text-align: right;
    font-family: "Alien Encounters Solid";
    font-size: 13px;
    font-style: normal;
    font-weight: bold;
    line-height: 0%; /* 0px */
    letter-spacing: 0.06px;
}
.top-timer {
    color: #50d1c6;
    font-family: "Alien Encounters Solid";
    font-size: 13px;
    font-style: normal;
    font-weight: bold;
    line-height: 0%;
    letter-spacing: 0.06px;
}
.w-mark {
    /*width: 13.001px;*/
    /*height: 13.439px;*/
}
