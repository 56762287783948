@font-face {
    font-family: "DIN-light";
    /* src: url(../../assets/styles/leaderboard.css"); */
    src: url(../../assets/fonts/DIN-Light.ttf);
}

@font-face {
    font-family: "DIN-medium";
    /* src: url(../../assets/styles/leaderboard.css"); */
    src: url(../../assets/fonts/DIN-Medium.ttf);
}

@font-face {
    font-family: "DIN-bold";
    /* src: url(../../assets/styles/leaderboard.css"); */
    src: url(../../assets/fonts/DIN-Bold.ttf);
}

@font-face {
    font-family: "DIN Condensed";
    /* src: url(../../assets/styles/leaderboard.css"); */
    src: url(../../assets/fonts/DINCond-Regular.otf);
}
@font-face {
    font-family: "Bardon Medium";
    src: url(../../assets/fonts/BrandonText-Medium.otf);
}
@font-face {
    font-family: "Bardon Bold";
    src: url(../../assets/fonts/BrandonText-Bold.otf);
}
@font-face {
    font-family: "Conthrax";
    src: url(../../assets/fonts/conthrax-sb.ttf);
}

body {
    overflow-x: hidden;
}

.css-1utwwx4 {
    line-height: 18px;
    -webkit-box-align: center;
    align-items: center;
    margin-bottom: 8px;
    display: inline-block;
    position: relative;
    white-space: nowrap;
    background: rgb(78 76 76);
    /* background: rgba(0, 0, 0, 0.15); */
    color: black;
    border-radius: 3em;
    padding: 5px;
    width: 60%;
}

/* .css-6hhrgj.css-6hhrgj {
    border-collapse: collapse;
    border-spacing: 0px;
    color: rgb(51, 51, 51);
    font-size: 13px;
    line-height: 20px;
    text-align: left;
    width: 100%;

} */

.css-1utwwx4 input {
    appearance: none;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    margin: 0px;
    padding: 0px;
    border: none;
    background: transparent;
    cursor: pointer;
    border-radius: 3em;
}

/* .css-1utwwx4 span:first-of-type {
    padding-right: 8px;
} */
.css-1utwwx4 span {
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    line-height: 1;
    letter-spacing: 2px;
    cursor: pointer;
    display: inline-block;
    padding: 8px 10px;
    transition: all 100ms ease-out 0s;
    user-select: none;
    border-radius: 3em;
    color: #b1b1b1;
    /* color: rgba(51, 51, 51, 0.6); */
    background: transparent;
}

/*.css-1utwwx4 input:not(:checked) ~ span:first-of-type {*/
/*    background: grey;*/
/*    !* background: rgb(255, 255, 255); *!*/
/*    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 2px;*/
/*    color: white;*/
/*    padding: 9px 21px;*/
/*}*/
.css-1utwwx4 > .highlight {
    background: grey;
    /* background: rgb(255, 255, 255); */
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 2px;
    color: white;
    padding: 9px 21px;
}

.card1-header {
    text-align: center;
    margin-top: -14px;
    background: black !important;
    width: 60%;
    color: white !important;
    font-size: 20px;
    font-weight: 700;
    border-radius: 30px !important;
    border: 2px solid aqua !important;
}

.card1 {
    border-radius: 30px !important;
    border: 2px solid aqua !important;
}

.card1-subtitle {
    margin-left: auto;
    font-size: 12px;
    color: grey;
}

.card1-text {
    background: grey;
    color: white;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    border-radius: 10px;
}

.card1-body {
    background: rgba(24, 23, 23, 0.692);
    color: white !important;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
}

.card1-img {
    border-radius: 20px !important;
    border: 2px solid aqua;
    box-shadow: 0 0 15px aqua;
}

.dropdown-toggle {
    border-radius: 30px !important;
    background: rgb(160, 160, 160);
    padding: 10px 18px;
}

.image-icon {
    /*height: 55px;*/
    /*width: 55px !important;*/
    position: absolute;
    top: 15%;
    left: 4%;
    border-radius: 30px !important;
    box-shadow: none !important;
    border: 2px solid aqua;
}

.dropdown-toggle::after {
    margin-left: 0 !important;
}

.col-21 {
    display: flex;
    justify-content: center;
}

/* NEWS FEED STYLING */

.feed-card {
    background-color: rgba(46, 56, 53, 1) !important;
    border-radius: 26px !important;
}

.user-name {
    /* font-family: 'DIN-bold'; */
    font-weight: 400;
    size: 18px;
    line-height: 21.6px;
    color: rgba(255, 255, 255, 1);
}

.location-div {
    background: rgba(31, 37, 35, 1);
    border-radius: 12px;
    display: inline-flex; /* This will make the div only as wide as its content */
    align-items: center; /* This will vertically center the items in the div */
    padding: 5px 10px; /* Adjust padding as needed */
    max-width: 100%;
}

.user-locaton {
    color: rgba(0, 174, 94, 1);
    /*font-weight: 300;*/
    font-size: 14px; /* Adjust font-size as needed */
    line-height: 12px;
    margin-left: 10px; /* Space between image and text */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    /*max-width: calc(100% - 30px); !* Adjust the max-width to prevent overflow *!*/
}

.location-img {
    width: 10px;
    height: 15px;
    /*width: 20px; !* Define your width *!*/
    /*height: 20px; !* Define your height *!*/
    /*align-self: center;*/
}

.post-img {
    border-radius: 16px !important;
    max-width: 100%;
    /* width: 100% !important; */
}

.action-col {
    color: rgba(255, 255, 255, 1);
    /* font-family: "DIN-bold"; */
    font-weight: 400;
    size: 20px;
    line-height: 24px;

    display: flex;
    /* justify-content:space-between; */
    /* justify-content:space-evenly; */
}

.like-img {
    width: auto;
    height: 90%;
}

.post-comments {
    color: rgba(255, 255, 255, 1);
    /* font-family: "DIN-bold"; */
    font-weight: 400;
    size: 18px;
    line-height: 21.6px;
}

.post-sub {
    color: rgba(174, 174, 174, 1) !important;
    font-family: "DIN Condensed";
    font-weight: 300;
    size: 16px;
    line-height: 19.2px;
}

.post-bar {
    width: auto;
    height: 20px;
    margin-top: -15px;
}

.user-img {
    /*width: auto;*/
    /*height: 100%;*/
}

/* Add New Post Styling */

.new-post-div {
    background: #262525;
    border-radius: 30px;
}

/* Select Post Styling */

.select-post-div {
    display: flex;
    /*justify-content: space-between;*/
    align-items: center;
}

/*.select-user-img {*/
/*    width: auto !important;*/
/*    height: 35px;*/
/*}*/

.select-user-name {
    font-family: "DIN Condensed";
    color: rgba(255, 255, 255, 1);
    font-size: 16px;
    font-weight: 600;
    line-height: 30px;
    /* line-height: 19px; */
    letter-spacing: 0em;
    text-align: center;
}

#exampleText {
    border-bottom: 2px solid rgba(109, 109, 109, 1) !important;
    border: none;
}

#exampleSearch {
    border-bottom: 2px solid rgba(109, 109, 109, 1) !important;
    border: none;
}

.new-post-input::placeholder {
    color: #b1b1b1 !important;
}

.new-post-input:focus {
    box-shadow: unset !important;
}

.new-post-input {
    color: #b1b1b1 !important;
    background: transparent !important;
    /*border-radius: 0 !important;*/
    /*min-height: auto !important;*/
    font-family: "Bardon Medium" !important;
    font-size: 16px !important;
    font-weight: 420 !important;
    /*line-height: 22px !important;*/
    letter-spacing: 0.05em;
}

.Post-btn {
    width: 100%;
    /* height: 57px; */
    border-radius: 12px !important;
    background: rgba(46, 56, 53, 1) !important;
    color: rgba(177, 177, 177, 1) !important;
    font-family: "Brandon Medium" !important;
    font-size: 16px;
    font-weight: 420;
    line-height: 22px;
    letter-spacing: 0.005em;
    text-align: center;
    border: none !important;
}

.main-post-btn {
    background: rgba(122, 68, 220, 1) !important;
    color: rgba(255, 255, 255, 1) !important;
    width: 100%;
    height: 57px;
    border-radius: 12px !important;
    font-family: "Brandon Medium" !important;
    font-size: 16px;
    font-weight: 420;
    line-height: 22px;
    letter-spacing: 0.005em;
    text-align: center;
    border: none !important;
}

.react-datetime-picker__wrapper {
    border: none !important;
}

.us-title {
    font-family: "Conthrax" !important;
    font-size: 9px;
    font-weight: 600;
    line-height: 15px;
    letter-spacing: 0.23em;
    text-align: left;
    color: rgba(157, 255, 80, 1) !important;
}

.us-title11 {
    color: rgba(80, 129, 255, 1) !important;
}

.us-title12 {
    color: rgba(122, 122, 122, 1) !important;
}

.us-title1 {
    font-family: "DIN Condensed";
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(255, 255, 255, 1) !important;
}

.us-title2 {
    font-family: "DIN Condensed";
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(121, 121, 121, 1) !important;
}

.us-points {
    font-family: "Bardon Bold" !important;
}
.feed-card1 {
    background-color: rgba(30, 31, 31, 1) !important;
    border-radius: 26px !important;
}

.custom-row {
    border-bottom: 2px solid rgba(64, 64, 64, 1) !important;
}

.overlay {
    position: fixed; /* Or absolute */
    top: 10%;
    left: 0;
    right: 0;
    bottom: 6%;
    background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent black */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.centered-button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: #34d683 !important;
    border: none !important;
}

.Message {
    /*width: 100%;*/
    height: 40px;
    border-radius: 10px;
    /*background-color: #40414f;*/
    background-color: transparent;
    /*transform: translate(0px,0px);*/
    /*display: flex;*/
    /*align-items: center;*/
    /*justify-content: space-between;*/
    border: 1px solid #8a908e;
}

.MsgInput {
    font-size: 14px;
    border-radius: 5px;
    background-color: transparent;
    /*background-color: #40414f;*/
    border: none;
    outline: none;
    width: 100%;
    height: 30px;
    transform: translate(5px, -3px);
    color: white;
    padding: 10px;
    /*font-weight: bold;*/
    margin-top: 3px;
}

.SendSVG {
    /*width: 30px;*/
    height: 30px;
    border-width: 50px;
    /*transform: translate(35px,5px);*/
    background-size: 10px;
    border-radius: 5px;
    transition: 0.3s;
    /*margin-bottom: 8px;*/
    /*background-color: #6a6c6e;*/
}

.SendSVG:hover {
    /*background-color: #6a6c6e;*/
}

.l {
    position: absolute;
    width: 1%;
    height: 30px;
    background-color: #2e2f38;
    transform: translate(-20px, 5px);
}
.comment-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.comment-time {
    font-size: 0.8em; /* Adjust as needed */
    color: #666; /* Adjust the color as needed */
}

.success-button-bg {
    background-color: #34d683 !important;
}

.cocacola-points {
    bottom: -1%;
    left: 5%;
}
.cocacola-points > div {
    color: white !important;
    font-size: 14px;
    -webkit-text-stroke: 0.4pt black;
}
