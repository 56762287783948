@font-face {
    font-family: "Conthrax";
    src: url(../fonts/conthrax-sb.ttf);
}

.dp-container {
    background-color: #e2dde3;
    margin-right: 10px;
    margin-top: -60px;
    padding: 5px;
    border-radius: 15px;
}

.public-dp {
    border-radius: 15px;
}

.page-bg {
    background-color: #e7e7e7;
}

.section-bg {
    background-color: #f5f5f5;
}

.pop-up-container {
    position: absolute;
    width: 100%;
    top: 2%;
    padding: 0 2%;
    color: #ffffff;
    z-index: 100;
}

.pop-up {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #0c0b0b;
    padding: 15px;
    border-radius: 11px;
    border: 1px solid #a9acb9;
}

.active-text {
    color: #24d294;
}

.public-register-button {
    font-size: 11px !important;
    padding: 8px 4px !important;
    border-radius: 6px !important;
    width: 80px;
    line-height: 1.2 !important;
    background-color: #24d294 !important;
    border: none !important;
}
.follow-playlist-button {
    font-family: "Conthrax" !important;
    font-size: 9px !important;
    padding: 8px 4px !important;
    border-radius: 6px !important;
    width: 80px;
    line-height: 1.2 !important;
    background-color: #7d34d8 !important;
    border: none !important;
}

.viewOnly-timer {
    font-family: "Conthrax" !important;
    font-size: 9px !important;
    width: 15%;
}
