@font-face {
    font-family: "Conthrax";
    src: url(../../assets/fonts/conthrax-sb.ttf);
}

@font-face {
    font-family: "DIN Condensed";
    src: url(../../assets/fonts/DINCond-Regular.otf);
}

.mywave-bg {
    /*background-image: url("../images/Header/viewtrakr-bg.png") !important;*/
    background-color: #000000;
    background-repeat: no-repeat;
    background-size: cover;
    /*min-height: 100vh;*/
    height: 100vh;
    width: 100%;
    overflow: hidden;
    /*display: flex;*/
    /*flex-direction: column;*/
    /*justify-content: space-between;*/
}

.sector-view-header {
    height: 20vh;
    background-image: url("/src/assets/images/viewTrakr/ws_back.png");
    background-size: cover;
    width: 93% !important;
    /*padding: 23px;*/
    /* margin-left: auto;
    margin-right: auto;
     */
    position: relative;
    display: grid;
    place-items: center;
    border-collapse: collapse;
    border-radius: 26px;
}
.score-points {
    position: absolute;
    top: 61%;
    color: #fff;
    text-align: center;
    font-family: Conthrax;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.4px;
}

.sector-view {
    height: 22.5vh;

    /*width: 90% !important;*/
    /*padding: 23px;*/
    color: aqua !important;
    margin-left: auto;
    margin-right: auto;
    background: #1e1f1f;
    border-collapse: collapse;
    border-radius: 26px;
}

.hidden {
    visibility: hidden;
}

.carousel-container {
    position: relative;
}

.carousel-arrows-left {
    position: absolute;
    top: 16px;
    display: flex;
    transition: opacity 0.3s ease; /* Add a transition for opacity */
}
.carousel-arrows-right {
    position: absolute;
    top: 16px;
    right: -33px;
    display: flex;
    transition: opacity 0.3s ease; /* Add a transition for opacity */
}

.arrow-button {
    /* Style your arrow buttons here */

    color: #000;
    border: none;
    cursor: pointer;
    width: 50%;
    height: 50%;
    border-radius: 50%;
    z-index: 11000;
    transition: opacity 0.3s ease;
    /* Adjust font size as needed */
}

.arrow-button:hover,
.arrow-button:focus {
    /* Focus is important for accessibility */
    opacity: 0.7;
}
/* Add your existing styles for .carousel-slider, .carousel-profile, .profile-rounded, etc. */

/* Additional class for fade-in effect */
.fade-in {
    transition: opacity 0.3s ease;
    opacity: 1;
}

.fade-in:hover,
.fade-in:hover {
    opacity: 0.7;
}

.carousel-profile {
    width: 65px !important;
    height: 65px;
    /*background-color: #C6C6C6;*/
    border-radius: 5px;
}

.sector-tab-header {
    border-radius: 40px;
    height: 29px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    color: white;
}
.sector-tab {
    height: 11.5vh;
    width: 97%;
    color: aqua !important;
    margin-left: auto;
    margin-right: auto;
    border-radius: 26px;
    background: #1e1f1f;
}

.sector-tab-details > div {
    display: flex;
    justify-content: space-between;
    padding-left: 12px;
    padding-right: 12px;
    font-weight: bolder;
}

.profile-rounded {
    border-radius: 50% !important;
}

.sector-main {
    padding-left: 6px;
    padding-right: 6px;
    overflow-y: auto;
    height: 81vh;
}

.sector-heading {
    font-family: Conthrax;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 2.4px;
}

.sub-heading {
    text-align: right;
    font-family: Conthrax;
    font-size: 8px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.sectors-containers {
    /* height: 61vh;
    overflow-y: auto;
    overflow-x: hidden; */
    padding: 0px 23px;
    /*margin-bottom: 40px;*/
    /*width: 90%;*/
    /* border: 1px solid #515151 !important; */
    border-collapse: collapse;
    scrollbar-color: transparent transparent; /* Firefox */
    scrollbar-width: thin; /* Firefox */
}

/* WebKit browsers (Chrome, Safari) */
.sectors-containers::-webkit-scrollbar {
    width: 0px; /* Adjust the width based on your design */
}

.sectors-containers::-webkit-scrollbar-thumb {
    background-color: transparent; /* Set the thumb color to be transparent */
}

.sectors-containers::-webkit-scrollbar-track {
    background-color: transparent; /* Set the track color to be transparent */
}

.slick-track {
    margin-left: 0 !important;
}
.sector-details {
    padding-left: 30px !important;
    padding-right: 30px !important;
}
.sector-details-label {
    font-family: "DIN Condensed";
    text-align: left;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    display: flex;
    line-height: 130%;
    flex-direction: column;
}

.sector-details-value {
    font-family: "DIN Condensed";
    text-align: right;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 130%;
    display: flex;
    flex-direction: column;
}

.carousel-slider {
    margin-top: 16px;
}

.disable-sector-tab {
    border-radius: 26px;
    background: #1e1f1f;
    color: #454545;
}

.disable-sector-tab-header {
    border-radius: 40px;
    height: 29px;
    background: #454545 !important;
    color: #626262 !important;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

@media only screen and (max-width: 375px), (max-height: 700px) {
    .sectors-containers {
        /* height: 61vh;
        overflow-y: auto;
        overflow-x: hidden; */
        margin-bottom: 40px;
        /* border: 1px solid #515151 !important; */
        border-collapse: collapse;
    }
    .score-points {
        position: absolute;
        top: 64%;
    }
    .sector-view {
        height: 28vh;
        /*width: 97% !important;*/
        padding: 2px;
    }

    .carousel-slider {
        margin-top: 10px;
    }
    .sector-tab {
        height: 15vh;
        padding: 2px;
    }

    /* Your styles for devices with a specific width and height here */
}

@media only screen and (max-height: 660px) {
    .sectors-containers {
        height: 49vh;
        overflow-y: auto;
        overflow-x: hidden;
        margin-bottom: 40px;
        /* border: 1px solid #515151 !important; */
        border-collapse: collapse;
    }
    .sector-view {
        height: 34vh;
        /*width: 97% !important;*/
        padding: 2px;
    }
    .carousel-slider {
        margin-top: 30px;
    }
    /* Your styles for devices with a specific width and height here */
}

@media only screen and (max-width: 280px) {
    .sectors-containers {
        height: 44vh;
        overflow-y: auto;
        overflow-x: hidden;
        margin-bottom: 40px;
        /* border: 1px solid #515151 !important; */
        border-collapse: collapse;
    }
    .sector-main {
        margin-top: 15px !important;
    }
    .sector-tab {
        height: 21vh;
        padding: 2;
    }

    .tab-view {
        padding: 0 5px !important;
    }
    /* Your styles for devices with a specific width and height here */
}
