.InputContainer {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    padding-left: 15px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.075);
}

.input, .input:focus {
    border: none !important;
    box-shadow: none !important;
}

.labelforsearch {
    cursor: text;
    padding: 0px 12px;
}

.searchIcon {
    width: 13px;
}

/*.border {*/
/*    height: 40%;*/
/*    width: 1.3px;*/
/*    !*background-color: rgb(223, 223, 223);*!*/
/*}*/

/*.searchIcon path {*/
/*    fill: rgb(114, 114, 114);*/
/*}*/
