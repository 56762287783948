@font-face {
    font-family: "DIN Condensed";
    /* src: url(../../assets/styles/leaderboard.css"); */
    src: url(../fonts/DINCond-Regular.otf);
}

body {
    font-family: "DIN Condensed" !important;
    /* overflow: hidden !important; */
}

.btn {
    font-family: "DIN Condensed";
}

.fs-12 {
    font-size: 12px;
}

.fs-10 {
    font-size: 10px;
}

.fs-14 {
    font-size: 14px;
}

.fs-8 {
    font-size: 8px;
}

.fs-15 {
    font-size: 15px;
}

.full-screen-height {
    min-height: 100vh;
}

.menu {
    position: fixed;
    bottom: -100%;
    left: 0;
    right: 0;
    transition: bottom 0.3s ease-in-out;
    z-index: 20;
}
.menu-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
    z-index: 10; /* Adjust z-index to be below the menu but above other content */
}

.menu.open {
    bottom: 6%;
}

.bottom-menu {
    background-color: #3e4744;
    color: #ffffff;
    /* height: 7vh; */
    border-radius: 10px 10px 0 0;
}

.cropImage-Modal {
    max-height: "75%";
    max-width: "75%";
    margin: "auto";
    touch-action: "none";
}
