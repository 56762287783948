@font-face {
    font-family: "Brandon Medium";
    src: url(../../assets/fonts/BrandonText-Medium.otf);
}

.auth-root {
    font-family: "Brandon Medium", serif;
}

.auth-inputs {
    background-color: #d9d9d9 !important;
}

.auth-inputs > input {
    background: transparent !important;
}

.contain {
    height: 100vh;
    background-color: black;
}

.auth-bg {
    background-color: black;
}

.auto-height {
    height: auto;
}

.form-box {
    padding: 30px 0px;
    max-width: 300px;
    margin: 0 auto;
}

.form-auth {
    border: none !important;
    background-color: #f0f0f0 !important;
    /* font-size: 12px !important; */
    color: #333333 !important;
    border-radius: 0px !important;
}

.form-auth::placeholder {
    color: #999999 !important;
}

.form-auth:focus {
    outline: none;
}

.button {
    background-color: #20c054 !important;
    border: none !important;
    color: white !important;
    padding: 20px 20px !important;
    margin-top: 20px;
    border-radius: 10px !important;
}

.buttonColor {
    background-color: #2e3835 !important;
}

.form-group {
    border-radius: 10px !important;
    padding: 20px !important;
    background-color: #f0f0f0;
}

hr {
    border: 0.5px solid #3d3a3a !important;
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;
}

.custom-button {
    display: flex;
    align-items: center;
    border-radius: 10px !important;
    text-align: left !important;
    position: relative;
    padding: 0;
    background-color: white !important;
    color: black !important;
    border: none;
    margin-top: 20px;
}

.custom-button .icon {
    margin-right: 10px;
}

.custom-button .text {
    color: #1677f3 !important;
    font-weight: 500;
    position: absolute;
    left: calc(38% - 1px);
    top: 34%;
}

.custom-button::before {
    content: "";
    width: 2px;
    height: 70%;
    background: #e4e4e4;
    position: absolute;
    left: calc(25% - 1px); /* Positioned in the middle */
    top: 50%;
    transform: translateY(-50%);
}

.text-mute {
    color: #68716e;
}

.small-text {
    font-size: small;
}

.second-color {
    color: #20c054;
}

.reg-success {
    color: #dddddd;
}

.small-error-message {
    background-color: #f8d7da;
    color: #721c24;
    padding: 10px;
    border-radius: 10px;
    margin: 0px 40px;
    text-align: center;
}
.modal {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    padding: 0 5%;
}

.modal-body {
    background: #d9d9d9 !important;
    border-radius: 20px !important;
    /*width: 80vw;*/
}

.modal-content {
    background: #d9d9d9 !important;
    border-radius: 20px !important;
}
