.main {
    height: 81vh;
    overflow-y: auto;
}

.add-post-button {
    position: absolute;
    right: 5%;
    bottom: 10%;
    z-index: 50;
}
