@font-face {
    font-family: "Bardon Medium";
    src: url("../fonts/BrandonText-Medium.otf");
}

@font-face {
    font-family: "Bardon Bold";
    src: url("../fonts/BrandonText-Bold.otf");
}

.REGISTER .span {
    color: #ffffff;
}
.text-wrapper-10 {
    color: #50d1c6;
}

.contains {
    background-color: black !important;
    height: 100vh;
}

.cover_font {
    color: #5d5d5d;
}

.card-bg {
    background-color: #313131 !important;
    border-top: 0 !important;
    padding-right: 2px !important;
    height: 120px !important;
    border-radius: 20px !important;
    border: 0px !important;
    position: relative;
    text-align: center;
}
.p-profile {
    padding: 32px !important;
}

.profile-img {
    position: relative;
    height: 100px;
    width: 100px;
    background: #313131;
    border: 4px solid #0c0b0b;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.edit-profile {
    position: absolute;
    top: 80%;
    left: 90%;
}

.editform {
    margin-top: 100px;
    /* padding: 0 20px; */
}

.profile-input {
    border: none !important;
    font-family: "Bardon Medium";
    background-color: black !important;
    color: #b1b1b1 !important;
    border-radius: 8px !important;
    padding: 0 20px;
}

.profile-input:disabled {
    border: none !important;
    font-family: "Bardon Medium";
    background-color: #2e3835 !important;
    color: #b1b1b1 !important;
    border-radius: 8px !important;
    padding: 10px 20px;
    font-style: italic;
}

.profile-input::placeholder {
    color: #b1b1b1 !important;
}

.profile-input:focus {
    outline: none;
}

.profile-hr {
    border: 1.7px solid #ffffff !important;
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;
}

.create-reg {
    color: #328deb;
    font-family: "Bardon Bold";
}

.linked-copied {
    font-family: "Bardon Bold" !important;
}

.bg-reg {
    background-color: #4184c9 !important;
    border: none !important;
}
.form-select-view {
    font-family: "Bardon Medium";
    padding: 15px 25px !important;
    background-color: #2e3835 !important;
    color: #b1b1b1 !important;
    font-size: 14px !important;
    border: none !important;
}

.form-select-view:focus {
    outline: none !important;
}

.form-select-view::placeholder {
    color: #b1b1b1 !important;
}

.group-text {
    border-right: none !important;
    background-color: #2e3835 !important ;
    border: none !important;
    margin-left: 20px;
}

.settingsRoundedImg {
    width: 100%;
    height: 100%;
}

.height-bg {
    /*height: 120px !important;*/
    /*position: relative !important;*/
    /*background-repeat: no-repeat;*/
    /*background-size: cover;*/

    border-radius: 20px !important;
    height: 120px !important;
    position: relative !important;
    background-size: cover;
    background-repeat: no-repeat;
}

.handle_Cover {
    position: absolute;
    bottom: 0%;
    right: 0%;
}
